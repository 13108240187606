<template>
  <page-header-wrapper content="设置角色前端功能权限">
    <a-card class="card" :title="roleInfo.roleName" :bordered="false">
      <template>
        <a-form :form="form" class="form" v-if="isShowForm">
          <a-row class="form-row" :gutter="24" v-for="(module, index) in moduleList" :key="index">
            <a-col :lg="6" :md="12" :sm="24" v-for="(row, index1) in module" :key="index1">
              <a-checkbox
                :value="row.keyWord"
                :checked="moduleKeyArr.indexOf(row.keyWord) > -1 ? true : false"
                @change="moduleChange"
              >
                {{ row.cmenuName }}
              </a-checkbox>
            </a-col>
            <a-divider :dashed="true"></a-divider>
          </a-row>
          <a-form-item>
            <span class="table-page-search-submitButtons">
              <a-button
                type="primary"
                @click="gotoBack"
                style="margin-right: 10px;"
              >
                返回
              </a-button>
              <a-button type="primary" @click="updateFrontPermissionApi">设置</a-button>
            </span>
          </a-form-item>
        </a-form>
      </template>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { gridModuleList } from '@/api/gridmodule'
import { gridRoleInfo, updateFrontPermission } from '@/api/gridrole'

export default {
  name: 'ModulePower',
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addpower' }),
      loading: false,
      memberLoading: false,
      roleParam: {
        roleId: 0
      },
      roleInfo: {
        frontPermission: [],
        roleId: 0
      },
      routeList: [],
      openKeys: ['userAll'],
      defaultExpandedKeys: [],
      rolePowerParams: {
        roleId: 0,
        routePower: [],
        modelPower: []
      },
      defaultExpandAll: true,
      moduleList: [],
      moduleKeyArr: [],
      isShowForm: true
    }
  },
  created () {
    this.isShowForm = true
    this.gridModuleListApi()
    if (this.$route.query.roleId !== 0 && this.$route.query.roleId !== undefined && this.$route.query.roleId !== '') {
      this.roleParam.roleId = parseInt(this.$route.query.roleId)
      this.gridRoleInfoApi()
    }
  },
  watch: {
    $route (val) {
      if (val.name === 'GridModulePower') {
        this.isShowForm = false
        this.$nextTick(function () {
          this.isShowForm = true
        })
        this.gridModuleListApi()
        if (this.$route.query.roleId !== 0 && this.$route.query.roleId !== undefined && this.$route.query.roleId !== '') {
          this.roleParam.roleId = parseInt(this.$route.query.roleId)
          this.gridRoleInfoApi()
        }
      }
    }
  },
  methods: {
    gotoBack () {
      this.$router.push({ path: '/role/role-list' })
    },
    gridModuleListApi () {
      const _this = this
      gridModuleList().then((res) => {
        if (res.errorCode === 0) {
          _this.moduleList = res.result.group
          console.log('gridModuleList', res)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    moduleChange (e) {
      console.log('moduleChange', e)
      if (e.target.checked) {
        this.moduleKeyArr.push(e.target.value)
      } else {
        var index = this.moduleKeyArr.indexOf(e.target.value)
        if (index > -1) {
          this.moduleKeyArr.splice(index, 1)
        }
      }
      console.log('moduleKeyArr', this.moduleKeyArr)
    },
    updateFrontPermissionApi () {
      const _this = this
      updateFrontPermission({ frontPermission: _this.moduleKeyArr, roleId: _this.roleParam.roleId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success(res.errorMsg)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    gridRoleInfoApi () {
      const _this = this
      gridRoleInfo(_this.roleParam).then((res) => {
        if (res.errorCode === 0) {
          _this.roleInfo = res.result
          _this.moduleKeyArr = res.result.frontPermission
          console.log(_this.roleInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .card{
    margin-bottom: 24px;
  }
  .popover-wrapper {
    /deep/ .antd-pro-pages-forms-style-errorPopover .ant-popover-inner-content {
      min-width: 256px;
      max-height: 290px;
      padding: 0;
      overflow: auto;
    }
  }
  .antd-pro-pages-forms-style-errorIcon {
    user-select: none;
    margin-right: 24px;
    color: #f5222d;
    cursor: pointer;
    i {
          margin-right: 4px;
    }
  }
  .antd-pro-pages-forms-style-errorListItem {
    padding: 8px 16px;
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background: #e6f7ff;
    }
    .antd-pro-pages-forms-style-errorIcon {
      float: left;
      margin-top: 4px;
      margin-right: 12px;
      padding-bottom: 22px;
      color: #f5222d;
    }
    .antd-pro-pages-forms-style-errorField {
      margin-top: 2px;
      color: rgba(0,0,0,.45);
      font-size: 12px;
    }
  }
</style>
